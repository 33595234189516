
/* Additional styles */
@import './utility-patterns.css';
@import './range-slider.css';
@import './toggle-switch.css';
@import './theme.css';

@tailwind  base;
@tailwind  components;
@tailwind utilities;





@layer utilities {
    .rtl {
        direction: rtl;
    }
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=""] {
    display: none;
}

/*Form Style*/

form {
    max-width: 500px;
    margin: 0 auto;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

input, textarea, button {
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

button {

    color: #fff;
    border: none;
    cursor: pointer;
}

h1{
    font-size: 2.3rem !important;
}