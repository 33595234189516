

.App {

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.openText{
  color:#0ea5e9;
  display: inline-block; /* Para mantener el espacio reservado durante el cambio */
  width: 5rem; /* Ajusta según la longitud máxima del texto (WORK! o HELP!) */
  text-align: left;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes textChange {
  0%, 50% {
    content: "WORK!";
  }
  50%, 100% {
    content: "HELP!";
  }
}

.text-flip::after {
  content: "WORK!";
  animation: textChange 1s infinite;
}
.required, .message-error {
  color: #cb4d6a;
}

.message-success{
  color: #739335;
}

/*columns checkboxes*/
.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 10px; /* Space between items */
}

.checkbox-item {
  display: flex;
  align-items: center; /* Align checkbox and label vertically */
  gap: 5px; /* Space between the checkbox and label */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .checkbox-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for screens <= 600px */
  }
}

legend {
  display: block; /* Make it behave like a block-level element */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  font-size: inherit; /* Inherit the font size */
  color: inherit; /* Inherit the text color */
  line-height: inherit; /* Inherit line height */
  font-weight: bold;
}
.message-success {
  color: green;
  font-weight: bold;
}

.message-error {
  color: red;
  font-weight: bold;
}

.languageBtn {
  border-radius: 0.3rem !important;
  padding: 0.3rem !important; ;
}