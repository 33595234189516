@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Aspekta';
  src: url('./assets/fonts/Aspekta-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/* Aplica la clase no-scrollbar al html y body para que no se muestren las barras de desplazamiento */
html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: 'Aspekta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  /* Evitar mostrar scrollbar */
  -ms-overflow-style: none; /* IE y Edge */
  scrollbar-width: none; /* Firefox */
}

/* Chrome, Safari y Opera */
html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* Oculta la scrollbar en Webkit */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
